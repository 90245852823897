/* eslint-disable camelcase */

import extend from 'extend';

import { getRel } from '../helpers';

/** @type {TemplatesConstructor} */
export function commonTemplates(constructors, state) {
    const { elem, mElem, popupTemplate } = constructors;
    const { hasCurtain, i18n } = state;

    return {
        container: ({ url, services, customContent = {}, theme: { direction, limit, copy, popupDirection, size, colorScheme, shape, moreButtonType }, flags: { needExtendMessenger, asPopup, canCopy }}) => {
            const mainContent = asPopup ?
                popupTemplate('popup', {
                    services,
                    url,
                    copy: canCopy && copy,
                    popupDirection,
                    listDirection: direction,
                    customContent,
                    needExtendMessenger,
                    hasCurtain
                }).content :
                elem('list', {
                    direction,
                    services,
                    limit,
                    url,
                    copy,
                    canCopy,
                    popupDirection,
                    customContent,
                    moreButtonType,
                    needExtendMessenger
                });

            const content = [mainContent];

            if (hasCurtain) {
                content.push(mElem('copied-tooltip'));
            }

            if (needExtendMessenger) {
                content.push(elem('messenger-frame'));
            }

            return {
                mods: { size, mobile: hasCurtain, 'color-scheme': colorScheme, shape, 'as-popup': asPopup },
                content
            };
        },

        'messenger-frame': () => ({
            tag: 'iframe'
        }),

        list: ({
            services,
            limit = services.length,
            url = '',
            copy,
            canCopy,
            popupDirection,
            direction: listDirection,
            customContent,
            moreButtonType,
            needExtendMessenger
        }) => {
            if (limit === false) {
                limit = services.length;
            }

            const visible = services.slice(0, limit);
            const hidden = services.slice(limit);

            return {
                tag: 'ul',
                mods: { direction: listDirection },
                content: [
                    visible.map(service => elem('item', service)),
                    hidden.length === 0 && copy === 'extraItem' && elem('item_copy', url),
                    hidden.length > 0 && elem('item_more', {
                        services: hidden,
                        url,
                        copy: canCopy && copy,
                        popupDirection,
                        listDirection,
                        customContent,
                        moreButtonType: String(limit) === '0' && moreButtonType,
                        needExtendMessenger: limit !== services.length && needExtendMessenger
                    })
                ]
            };
        },

        item: (service = {}) => ({
            tag: 'li',
            mods: { service: service.name },
            content: elem('link', service)
        }),

        link: ({ location, title, mods, linkAttrs, iconUrl } = {}) => ({
            tag: 'a',
            attrs: {
                // eslint-disable-next-line no-script-url
                href: location || 'javascript:void(0);',
                rel: location && getRel(location),
                target: location && '_blank',
                title: title,
                ...linkAttrs
            },
            mods,
            content: [
                elem('badge', iconUrl),
                elem('title', title)
            ]
        }),

        badge: iconUrl => ({
            tag: 'span',
            content: [
                elem('icon', iconUrl)
            ]
        }),

        icon: iconUrl => ({
            tag: 'span',
            mods: {
                'messenger-contact': Boolean(iconUrl)
            },
            ...(iconUrl && {
                attrs: {
                    'data-icon-url': iconUrl
                }
            })
        }),

        title: title => ({
            tag: 'span',
            content: title
        }),

        item_more: ({ moreButtonType, ...params }) => extend(elem('item'), {
            mods: { more: true, 'has-pretty-view': Boolean(moreButtonType) },
            content: [
                elem('link_more', moreButtonType),
                popupTemplate('popup', params)
            ]
        }),

        link_more: moreButtonType => extend(elem('link'), {
            mods: { more: true, 'more-button-type': moreButtonType },
            content: [
                elem('badge_more'),
                moreButtonType === 'long' && elem('title', i18n.shareButton)
            ]
        }),

        badge_more: () => extend(elem('badge'), {
            mods: { more: true },
            content: elem('icon_more')
        }),

        icon_more: () => extend(elem('icon'), {
            mods: { more: true }
        }),

        item_copy: url => extend(elem('item'), {
            mods: { copy: true },
            content: [
                elem('link_copy'),
                elem('input_copy', url)
            ]
        }),

        link_copy: () => extend(elem('link', { title: i18n.copyLink }), {
            mods: { copy: true },
            content: [
                elem('badge_copy'),
                elem('title', i18n.copyLink)
            ]
        }),

        badge_copy: () => extend(elem('badge'), {
            mods: { copy: true },
            content: elem('icon_copy')
        }),

        icon_copy: () => extend(elem('icon'), {
            mods: { copy: true }
        }),

        input_copy: url => ({
            tag: 'input',
            attrs: {
                value: url
            }
        }),

        'messenger-contacts': () => ({
            mods: { [hasCurtain ? 'mobile' : 'desktop']: true }
        })
    };
}
